var Variables = {
    targetPoint: 0,
    totalPoints: 0,
    totalBrainwavePoint: 0,
    playCount: 0,
    shotSound: null,
    failSound: null,
    maxPlayCount: 3,
    maxnoOfballsthrowable: 8,
    pointPerCharacter: "+50",
    pointPerFailedCharacter: "-15",
    network: "",
    mobileNo: "",
    words: ["disproportionateness","counterdemonstration","internationalization",
            "immunohistochemistry","counterrevolutionist","indistinguishability",
            "overenthusiastically","uncharacteristically","antiestablishmentism",
            "magnetohyrodynamics"],
    word: "",
    apiBaseUrl: "https://apps.mobivs.com/services/BRAINWAVE/APPS/public/api/trivFive/",
    //apiBaseUrl: "https://apps.mobivs.com/services/BRAINWAVE_DEV/APPS/public/api/trivFive/",
    subscriptionApiBase: "https://apps.mobivs.com/services/BRAINWAVE/APPS/public/api/v1/",
};
export default Variables;